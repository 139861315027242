export default {
    dailyCalls: {
        red: 10,
        green: 20
    },
    weekCalls: {
        red: 70,
        green: 140
    }
};
