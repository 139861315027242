import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import ShadowFragment from '../../../Base/ShadowFragment';
import EditingBlockWrapper from '../../EditingBlockWrapper';

import estateL10n from '../../../../L10n/estate.L10n';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import WarehouseBlockEditing, { WAREHOUSE_FIELDS, INFRASTURCTURE_FIELDS } from '../EditBlocks/WarehouseBlockEditing';

@observer
class WarehouseBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            floorMaterialTypeType,
            liftType,
            liftAdditionalType,
            liftCount,
            liftLoadCapacity,
            cranageType,
            cranageLoadCapacity,
            cranageCount,
            gatesType,
            columnGrid
        } = item || {};

        return (
            <Card fluid color="green">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Склад</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Материал пола</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.WAREHOUSE_FLOOR_MATERIAL_TYPE_FIND(floorMaterialTypeType)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column>Лифт</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.WAREHOUSE_LIFT_TYPE_FIND(liftType)} />
                                        {liftAdditionalType && <Fragment>&nbsp;+{liftAdditionalType}</Fragment>}
                                        {liftCount && (
                                            <Fragment>
                                                <br />
                                                Количество: {liftCount}
                                            </Fragment>
                                        )}
                                        {liftLoadCapacity && (
                                            <Fragment>
                                                <br />
                                                Грузоподъемность: {liftLoadCapacity}
                                            </Fragment>
                                        )}
                                    </Grid.Column>

                                    <Grid.Column>Крановое оборудование</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.WAREHOUSE_CRANAGE_TYPE_FIND(cranageType)} />
                                        {cranageCount && (
                                            <Fragment>
                                                <br />
                                                Количество: {cranageCount}
                                            </Fragment>
                                        )}
                                        {cranageLoadCapacity && (
                                            <Fragment>
                                                <br />
                                                Грузоподъемность: {cranageLoadCapacity}
                                            </Fragment>
                                        )}
                                    </Grid.Column>

                                    <Grid.Column>Тип ворот</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={estateL10n.WAREHOUSE_GATES_TYPE_FIND(gatesType)} />
                                    </Grid.Column>

                                    <Grid.Column>Сетка колонн</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={columnGrid} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid columns={3} stackable>
                                <Grid.Row>
                                    {WAREHOUSE_FIELDS.map(
                                        field => item[field[0]] && <Grid.Column key={field[0]}>● {field[1]}</Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>

                            <Grid columns={3} stackable>
                                <Grid.Row>
                                    {INFRASTURCTURE_FIELDS.map(
                                        field => item[field[0]] && <Grid.Column key={field[0]}>● {field[1]}</Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <WarehouseBlockEditing item_id={item_id} {...item} />}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, WarehouseBlockShow);
